<template>
  <div class="page">
    <!--index.wxml-->
    <div class="custom flex_center" style="padding-top:50px">
      <div class="custom_header">
        <van-row>
          <van-col span="8" custom-class="customArrowLeft">
            <van-icon name="arrow-left" color="#FFF" custom-class="customHeaderIcon" @click="goBackPage"/>
          </van-col>
          <van-col span="8">
			
          </van-col>
          <van-col span="8"></van-col>
        </van-row>
      </div>
    </div>
    <div class="empty_custom" style="padding-top:50px"></div>
    <div class="container haveBottomTabBar">
      <div class="login_inp_box">
        <div class="sms_login_box">
          <div class="login_field">
			  <van-field
				  label="用户昵称"
				  v-model="account"
				  data-name="account"
				  clearable
				  placeholder="请输入用户昵称"
				  custom-style="margin-bottom:50px;border-bottom:2px solid #eee;"
			  />
		  </div>
		  <div class="login_field">
			  <van-field
				  label="手机号"
				  v-model="phone"
				  data-name="phone"
				  clearable
				  placeholder="请输入手机号码"
				  custom-style="margin-bottom:50px;border-bottom:2px solid #eee;"
			  />
		  </div>
		  <div class="login_field">
			  <van-field
				  label="验证码"
				  v-model="sms"
				  data-name="sms"
				  center
				  clearable
				  placeholder="请输入短信验证码"
				  :border="false"
				  use-button-slot
				  custom-style="margin-bottom:50px;border-bottom:2px solid #eee;"
			  >
				<van-button @click="sendPhoneCode" class="sms_send_btn" slot="button" size="small" type="defalut" :disabled="smsFlag">{{sendTime}}</van-button>
			  </van-field>
		  </div>
		  <div class="login_field">
			  <van-field
				  label="密码"
				  v-model="pwd"
				  data-name="pwd"
				  type="password"
				  placeholder="请输入密码"
				  :border="false"
				  custom-style="margin-bottom:50px;border-bottom:2px solid #eee;"
			  >
			  </van-field>
		  </div>
        </div>
        <div class="login_btn_box">
          <div class="login_btn">
            <van-button type="info" class="go_login_btn" bindgetuserinfo="submitRegister" open-type="getUserInfo" block round>提交信息</van-button>
          </div>
          <div class="go_regist">
            <van-button
                custom-class="change_login_type_btn"
                size="small" plain block round
                @click="goLoginPage"
            >
              已有账户，去登录
            </van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'register',
    data() {
      return {
        code:'',
        account:'',
        phone:'',
        sms:'',
        pwd:'',
        smsFlag:false,
        sendTime: '发送验证码',
        sendColor: '#363636',
        snsMsgWait: 60,
        loginType:1, //1 账号密码登录  2 手机验证码登录
        bottomTabBarActive:3, //底部导航位置
      }
    },
    components: {
    },
    created() {
    },
    methods: {
      //双向绑定input数据
      bindInputData:function(e){
        let _this = this;
        let dataset = e.currentTarget.dataset;
        let value = e.detail;
        let name = dataset.name;

        _this[name] = value;
        _this.name=_this[name]

        console.log(_this[name]);
      },
      //提交注册信息
      submitRegister:function(){
        wx.showLoading({
          title:'加载中'
        });
        // 查看是否授权
        let _this = this;
        
		//判断是否有推广人信息
		let spreadid = localStorage.setItem('spreadid');
		this.post(
		  this.API.relate,
		  {
		    account:_this.account,
		    phone:_this.phone,
		    sms:_this.sms,
		    pwd:_this.pwd,
		    spreadid:spreadid
		  }
		).then(response => {
		  if(response.err=='1') {
		    this.$toast(response.msg)
		    return false;
		  }
		  let token = response.data.token;
		  if(token!='') {
		    this.$toast('操作成功')
		
		   localStorage.setItem('userToken',token);
		   localStorage.setItem('user_id',response.data.user_id);
		   localStorage.setItem('user_level',response.data.level);
		    this.$router.replace({
		      name: 'Home',
		    })
		  }
		})

      },
      //发送验证码
      sendPhoneCode:function(){
        let phone = this.phone;
        console.log(phone);
        if(!(/^1[3456789]\d{9}$/.test(phone))){
          this.$toast("请填写正确的手机号码");
        } else {
          this.post(
            this.API.sendPhoneCode,
            {phone:phone}
          ).then(response => {
            console.log(response)
          })

          var inter = setInterval(function() {
            this.smsFlag= true
            this.sendColor= '#cccccc'
            this.sendTime= this.snsMsgWait + 's后重发'
            this.snsMsgWait= this.snsMsgWait - 1
            
            if (this.snsMsgWait < 0) {
              clearInterval(inter)
              this.sendColor= '#363636'
              this.sendTime= '发送验证码'
              this.snsMsgWait= 60
              this.smsFlag= false
            }
          }.bind(this), 1000);
        }
      },
      goBackPage:function() {
        this.$router.replace({
          name: 'login',
        })
      },
      goLoginPage:function(){
        this.$router.replace({
          name: 'login',
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #FFFFFF;
  }

  /* pages/login/index.wxss */
  :root {
    --button-baozi-background-color: #FFFFFF;
  }
  .login_inp_box {
    margin-top:60px;
    padding: 20px;
  }
  .login_inp_box .login_field {
  	  margin-bottom:50px;
  	  border-bottom:2px solid #ccc;
  }
  .login_type_btn {
    margin:-30px 20px;
    text-align: right;
    font-size:22px;
    color:#999;
  }
  .login_btn_box {
    margin-top:120px;
    padding:0px 50px;
  }
  .sms_send_btn {
    display: inline !important;
    color:#3089fd;
    background-color: transparent !important;
  }
  .change_login_type_btn {
    color:#999 !important;
    border:none !important;
  }
  .login_btn {
    margin-bottom: 60px;
  }
  .login_btn .go_login_btn {
  	  height: 84px !important;
  }
  .login_btn .go_unionpay_btn {
  	  height: 84px !important;
  	  border:2px solid #07c160;
  }

</style>
